/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as Const from '../../common/config';
import Page from '../../components/page';
import * as STORE from './store';
import Banners from '../../components/banners';

import './index.less';
import IMG_m1 from "../../common/images/m1.png";
import {useParams} from "react-router-dom";


const PagePrivacyModel = props => {
	const { type } = useParams();
	const [ data, setData ] = useState(null);
	useEffect(() => {
		const _data = STORE[type] || null;
		setData(_data);
	}, []);

	if(!data) return null;
	return (
		<Page {...{ page: 'privacy', title: data.title }} {...props}>
			<div className="qaBox" dangerouslySetInnerHTML={{ __html: data.content }} />
			<div className="ft50"></div>
			{/*<!--占位用误删-->*/}
		</Page>
	);
};

export default PagePrivacyModel;
