/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import * as Request from '../../common/request';
import * as config from '../../common/config';
import * as CONST from '../../common/const';
import Page from '../../components/page';

import './index.less';
import IMG_NODATA from "../../common/images/wushuju.jpg";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {
	ORDER_STATUS_APPOINT_CONFIRMED,
	ORDER_STATUS_APPOINT_TO_CONFIRM,
	ORDER_STATUS_COMPLETED,
	ORDER_STATUS_PAID,
	ORDER_STATUS_TO_APPOINT,
	ORDER_STATUS_VISIT_APPOINT_CONFIRMED,
	ORDER_STATUS_VISIT_APPOINT_TO_CONFIRM,
	ORDER_STATUS_VISIT_TO_APPOINT
} from "../../common/const";

const TABS = [
	{ id: 't1', label: '全部', query: {} },
	{ id: 't2', label: '待付款', cls: 'wfk', query: { status: [CONST.ORDER_STATUS_TO_PAY, CONST.ORDER_STATUS_TO_PAY1] } },
	{ id: 't3', label: '已付款', cls: 'yfk', query: { status: [ORDER_STATUS_PAID, ORDER_STATUS_TO_APPOINT, ORDER_STATUS_APPOINT_TO_CONFIRM, ORDER_STATUS_APPOINT_CONFIRMED] } },
	{ id: 't4', label: '已完成', cls: 'yfk', query: { status: [ORDER_STATUS_COMPLETED, ORDER_STATUS_VISIT_TO_APPOINT, ORDER_STATUS_VISIT_APPOINT_TO_CONFIRM, ORDER_STATUS_VISIT_APPOINT_CONFIRMED, CONST.ORDER_STATUS_VISIT_COMPLETED] } },
]

const PageOrderModel = props => {
	const history = useHistory();

	const [ orderList, setOrderList ] = useState([]);
	const getOrderList = async (data = {}) => {
		const result = await Request.get({
			url: '/order/list',
			data
		});

		if(!result) return;
		setOrderList(result.data.list);
	};
	useEffect(() => {
		getOrderList();
	}, [ 123 ]);

	const [ tab, setTab ] = useState(TABS[0]);

	return (
		<Page {...{ page: 'order', title: '我的服务预约' }} {...props}>
			{/*<!-- TAB BEING-->*/}
			<div className="orderTag">
				{
					TABS.map((item, idx) => {
						return (
							<div className={item.id == tab.id ? 'orderTagOn' : 'orderTagOff'} key={idx} onClick={() => {
								setTab(item);
								getOrderList(item.query);
							}}>{item.label}</div>
						)
					})
				}
			</div>
			{
				orderList?.length > 0 && <>
					{
						orderList.map((item, idx) => {
							let label = '';
							let labelCls = 'wfk';
							let btnCls = 'bt101';
							TABS.map(tabItem => {
								if(tabItem.query?.status?.includes(item.status)) {
									label = tabItem.label;
									if(label == '已付款' || label == '已完成') {
										labelCls = 'yfk';
										btnCls = 'bt100';
									}
								}
							});

							return (
								<div className="groupBox ma5050" key={idx}>
									<div className="groupTop">
										服务预约时间：{item.createdAt && moment(item.createdAt).format('YYYY-MM-DD HH:mm') || ''}
										<span className={labelCls}>状态：{label}</span>
									</div>
									<div className="groupPic">
										<div className="groupPicLf"><img src={item?.productInfo?.cover && config.imgPrefix + item?.productInfo?.cover[0]}/></div>
										<div className="groupPicRt">
											<div className="groupPicText01">{item?.productInfo?.name}</div>
											{/*<div className="groupPicText02">￥{item?.price}</div>*/}
											<div className="groupPicText03">{item?.processInfo?.extra}</div>
										</div>
									</div>
									<div className="groupShow">
										<button className={btnCls} type="submit" onClick={() => history.push(`/order/${item.id}`)}>查看服务预约详情</button>
									</div>
								</div>
							)
						})
					}
				</> || <div className="wushuju"><img src={IMG_NODATA} /></div>
			}

			<div className="ft50"></div>{/*<!--占位用误删-->*/}
		</Page>
	);
};

export default PageOrderModel;
