/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as Const from '../../common/config';
import Page from '../../components/page';
import Banners from '../../components/banners';

import './index.less';
import IMG_m1 from "../../common/images/m1.png";
import {privacy} from "./store";


const PagePrivacyModel = props => {

	return (
		<Page {...{ page: 'privacy', title: '协议政策' }} {...props}>
			<div className="helpBox">
				<a href="/protocol/user">
					<div className="menuWz">用户协议</div>
					<div className="menuRt20"><img src={IMG_m1} /></div>
				</a>
			</div>
			<div className="helpBox">
				<a href="/protocol/privacy">
					<div className="menuWz">隐私协议</div>
					<div className="menuRt20"><img src={IMG_m1} /></div>
				</a>
			</div>
			<div className="helpBox">
				<a href="/protocol/appointment">
					<div className="menuWz">预约服务协议</div>
					<div className="menuRt20"><img src={IMG_m1} /></div>
				</a>
			</div>
		</Page>
	);
};

export default PagePrivacyModel;
