/**
 * Created by jimmy on 2019/11/8.
 */

export const env = window.location.host.indexOf('shenman.net') > -1 ? 'product' : 'dev';

// export const apiPrefix = 'http://api.yeekin.cn/v1';
export const apiPrefix = '/api/v1';
export const imgPrefix = env == 'product' ? '/' : 'http://127.0.0.1:5687/';

// export const appId = env == 'product' ? 'wx820438f1cf9fdc3b' : 'wx6ef0bb6a5b9e3955';
export const appId = env == 'product' ? 'wx25dcebf1bc161b82' : 'wx6ef0bb6a5b9e3955';

export const regexpPattern = {
  mobile: /^1[3456789]\d{9}$/,
};
