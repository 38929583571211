/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as Const from '../../common/config';
import Page from '../../components/page';
import Banners from '../../components/banners';

import './index.less';
import IMG_F5 from "../../common/images/f5.png";
import IMG_b1 from "../../common/images/b1.jpg";
import IMG_b2 from "../../common/images/b2.jpg";
import IMG_b3 from "../../common/images/b3.jpg";
import IMG_ICON_01 from "../../common/images/icon01.png";
import IMG_ICON_02 from "../../common/images/icon02.png";
import IMG_ICON_03 from "../../common/images/icon03.png";
import IMG_ICON_04 from "../../common/images/icon04.png";
import IMG_ICON_05 from "../../common/images/icon05.png";
import IMG_s7 from "../../common/images/s7.jpg";
import IMG_zm02 from "../../common/images/zm02.jpg";
import IMG_zm03 from "../../common/images/zm03.jpg";
import IMG_zm04 from "../../common/images/zm04.jpg";
import IMG_FANHUI from "../../common/images/fanhui.png";
import IMG_ABOUT from "../../common/images/about.jpg";


const PageAboutModel = props => {

	return (
		<Page {...{ page: 'about', title: '关于我们' }} {...props}>
			<div className="aboutTop"><img src={IMG_ABOUT} /></div>
			<div className="aboutText">
				<div className="text02"><b>和携申满医疗科技（上海）有限公司</b></div>
				<div className="text02">和携科技有限公司成立于2017年，公司以临床为导向，基于自主知识产权的工艺体系，专注于高精准、高效能、高品质的干细胞技术研究与应用转化。公司核心技术源自于中国医学科学院北京协和医院的科研成果转化，并由北京协和医院专家团队联合研发。</div>
				<div className="text02">和携申满医疗科技（上海）有限公司，立足和携科技的干细胞产品，以细胞应用和再生医学技术为核心，致力于细胞科技产业领域的应用及发展。通过和携科技囊括细胞存储与制备、细胞临床研究、新药研发、健康管理、精准检测等业务，旨在打造完整的细胞全产业链闭环。</div>

			</div>

			<div className="ft50"></div>
		</Page>
	);
};

export default PageAboutModel;
