/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as config from '../../common/config';
import Page from '../../components/page';
import Banners from '../../components/banners';

import './index.less';

import IMG_bg1 from "../../common/images/bg01.jpg";
import {useHistory} from "react-router-dom";


const PageAboutModel = props => {
	const history = useHistory();
	const [ userInfo, setUserInfo ] = useState(null);
	const getUserInfo = async () => {
		const userInfo = Utils.user.getInfo();
		setUserInfo(userInfo);
		if(userInfo.nickname) {
			setName(userInfo.nickname);
		}
	};
	useEffect(() => {
		getUserInfo();
	}, [ 123 ]);

	const [ name, setName ] = useState('');
	const [ nameErrorTip, setNameErrorTip ] = useState(false);
	const [ mobile, setMobile ] = useState('');
	const [ mobileErrorTip, setMobileErrorTip ] = useState(false);
	const [ code, setCode ] = useState('');
	const [ codeErrorTip, setCodeErrorTip ] = useState(false);
	const [ codeImg, setCodeImg ] = useState(null);
	const getCodeImg = async () => {
		const result = await Request.get({
			url: '/user/imgcode/get'
		});
		if(!result) return;

		const codeImg = `data:image/svg+xml;base64,${window.btoa(result.data.img)}`;
		setCodeImg(codeImg);
	};
	useEffect(() => {
		getCodeImg();
	}, [123]);
	const submit = async () => {
		if(!name || !userInfo?.nickname) return setNameErrorTip(true);
		if(!mobile || !config.regexpPattern.mobile.test(mobile)) return setMobileErrorTip(true);
		if(!code) return setCodeErrorTip(true);

		const result = await Request.post({
			url: '/user/update',
			data: {
				nickname: name || userInfo.nickname,
				mobile: mobile,
				code
			}
		});
		if(!result) return;

		Utils._alert('修改成功');

		const result1 = await Request.get({
			url: '/user/get'
		});
		if(!result1) return;

		Utils.user.setInfo(result1.data);
		getUserInfo();
		history.push('/');
	};

	return (
		<Page {...{ page: 'login', showHeader: false }} {...props}>
			<div className="userBox ">
				<div className="userTop"><img src={IMG_bg1}/></div>
				<div className="userOne">
					<div className="logoinText">完善注册资料</div>
					<div className="EditText">
						<input id="textfield" type="text" className="Input1" defaultValue={userInfo?.nickname} placeholder="请填写姓名" onChange={e => {
							setName(e.target.value);
							setNameErrorTip(false);
						}} />
					</div>
					{ nameErrorTip && <div className="tipsShow">请填写姓名</div> }
					<div className="EditText">
						<input id="textfield" type="text" className="InputMob" maxLength={11} placeholder="请填写手机号码" onChange={e => {
							setMobile(e.target.value);
							setMobileErrorTip(false);
						}} />
					</div>
					{ mobileErrorTip && <div className="tipsShow">手机号码格式不对，请重新输入</div> }
					{/*<!--错误提示-->*/}

					<div className="EditText">
						<input id="textfield" type="text" className="InputYzm" placeholder="输入验证码" onChange={e => {
							setCode(e.target.value);
							setCodeErrorTip(false);
						}} />
						<div className="Yzm">
							<div className="YzmPic"><img src={codeImg} onClick={getCodeImg} /></div>
						</div>
					</div>
					{ codeErrorTip && <div className="tipsShow">验证码输入错误，请重新输入</div> }
					<div className="xieyi">提交即表示您已同意<a href="/protocol">《和捷用户协议及隐私协议》</a></div>
					<div className="selectFooterCt">
						<input name="" type="button" className="selectChoiceLeftBt" value="下一步" onClick={submit} />
					</div>

				</div>


			</div>
		</Page>
	);
};

export default PageAboutModel;
