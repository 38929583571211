/**
 * Created by jimmy on 2022/9/25.
 */

import React, { useState, useEffect } from 'react';
import {useHistory, useParams} from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import * as CONST from '../../common/const';
import * as config from '../../common/config';
import './index.less';
import Carousel from "../carousel";

const CompBannerModel = props => {
	const history = useHistory();
	const { style = {}, list = [], mode } = props;

	if(!list || list.length <= 0) return null;

	style.height = style.height / 375 * window.innerWidth;

	return (
		<section className="comp-banner-wrapper" style={style}>
			<Carousel {...{
				mode,
				height: style.height,
				slidesPerView: Math.min(list.length, 3.3),
				style: { height: style.height },
				list,
				renderItem: (item, idx) => {
					console.log('item', item);
					return (
						<div className="comp-banner-item" style={{ backgroundImage: `url(${item.cover})` }} key={idx} onClick={() => {
							if(item.targetType == CONST.BANNER_TARGET_TYPE_LINK && item.target.startsWith('http')) {
								window.location.href = item.target;
							}else if(item.targetType == CONST.BANNER_TARGET_TYPE_PRODUCT){
								history.push(`/service/${item.target}`);
							}else if(item.targetType == CONST.BANNER_TARGET_TYPE_ARTICLE){
								history.push(`/article/${item.target}`);
							}
						}} />
					)
				}
			}} />
		</section>
	);
};

export default CompBannerModel;
