/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import * as ContentLoader from 'react-content-loader';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as Const from '../../common/config';
import Page from '../../components/page';
import Banners from '../../components/banners';

import './index.less';

const PageHomeModel = props => {
	const [ userInfo, setUserInfo ] = useState(null);
	const getUserInfo = async () => {
		const userInfo = Utils.user.getInfo();
		setUserInfo(userInfo);
	};
	useEffect(() => {
		getUserInfo();
	}, [ 123 ]);

	const [ bannerList, setBannerList ] = useState([]);
	const getBannerList = async () => {
		const result = await Request.get({
			url: '/banner/list'
		});

		if(!result) return;
		setBannerList(result.data.list);
	};
	useEffect(() => {
		getBannerList();
	}, [ 123 ]);

	const [ productList, setProductList ] = useState([]);
	const getProductList = async () => {
		const result = await Request.get({
			url: '/product/list'
		});

		if(!result) return;
		setProductList(result.data.list);
	};
	useEffect(() => {
		getProductList();
	}, [ 123 ]);

	return (
		<Page {...{ page: 'home', showFooter: true, showHeader: false, title: '和携申满服务' }} {...props}>
			<section className="home-container">
				{
					!productList?.length &&!bannerList?.length && [{}, {}, {}].map((item, idx) => <div key={idx} style={{ width: '100%', marginBottom: 36, marginTop: idx == 0 ? 10 : 0 }}>
						<ContentLoader.List style={{ width: window.innerWidth*1.5 }} />
					</div>)
				}

				<div className="attestation">
					<span><img src={userInfo?.cover}/></span>
					<h1>{userInfo?.nickname}</h1>
					{/*<h2>ID:{userInfo?.serialNumber}</h2>*/}
				</div>
				{/*<!--幻灯 BEING-->*/}
				<Banners {...{
					style: { height: 175 },
					list: bannerList
				}} />
				{/*<!-- 产品列表-->*/}
				{
					productList?.length > 0 && <div className="listenVedio">
						{/*<!-- title BEING-->*/}
						<div className="titleMiss">
							<h2>细胞专区</h2>
							<h1>He jie Cell zone</h1>
							<h3></h3>
						</div>
						<div className="vedioCenter">
							{
								productList.map((item, idx) => {
									return (
										<div className="freeList" key={idx}>
											<a href={`/service/${item.id}`}>
												<span><img src={item.cover && item.cover[0] || null} /></span>
												<h1>{item.name}</h1>
												{/*<h3>价格：¥{item.price}</h3>*/}
											</a>
										</div>
									)
								})
							}
						</div>
					</div>
				}
				{/*<div className="ft50"></div>*/}
				{/*	<!--占位用误删-->*/}
			</section>
		</Page>
	);
};

export default PageHomeModel;
