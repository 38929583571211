/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as config from '../../common/config';
import Page from '../../components/page';
import Banners from '../../components/banners';

import './index.less';
import IMG_USER_01 from "../../common/images/user01.png";
import IMG_p5 from "../../common/images/p5.jpg";
import IMG_ICON_10 from "../../common/images/icon10.png";
import IMG_ICON_9 from "../../common/images/icon09.png";
import IMG_ICON_13 from "../../common/images/icon13.png";
import IMG_f1 from "../../common/images/f1.png";
import IMG_f3 from "../../common/images/f3.png";
import IMG_f6 from "../../common/images/f6.png";
import IMG_m1 from "../../common/images/m1.png";
import {_alert} from "../../common/utils";


const PageUserSettingModel = props => {
	const [ nickname, setNickname ] = useState('');
	const [ mobile, setMobile ] = useState('');
	const [ userInfo, setUserInfo ] = useState(null);
	const getUserInfo = async () => {
		const userInfo = Utils.user.getInfo();
		setUserInfo(userInfo);
		if(userInfo.mobile) {
			setMobile(userInfo.mobile);
		}
	};
	useEffect(() => {
		getUserInfo();
	}, [ 123 ]);

	const submitHandle = async () => {
		if(!config.regexpPattern.mobile.test(mobile)) {
			Utils._alert('手机号码格式不对');
			return;
		}
		const result = await Request.post({
			url: '/user/update',
			data: {
				nickname: nickname || userInfo.nickname,
				mobile: mobile || userInfo.mobile,
			}
		});
		if(!result) return;

		Utils._alert('修改成功');

		const result1 = await Request.get({
			url: '/user/get'
		});
		if(!result1) return;
		Utils.user.setInfo(result1.data);
		getUserInfo();
	};

	return (
		<Page {...{ page: 'userSetting', title: '修改资料' }} {...props}>
			<div className="certiBox">

				<div className="EditText">
					<input id="textfield" type="text" className="Input100" defaultValue={userInfo?.nickname} onChange={e => setNickname(e.target.value)} />
				</div>
				<div className="EditText">
					<input id="textfield" type="text" className="Input110"maxLength={11} defaultValue={userInfo?.mobile} onChange={e => setMobile(e.target.value)} />
				</div>
				<div className="selectFooterCt">
					<input name="" type="button" className="selectChoiceLeftBtNone" onClick={submitHandle} value="保存" />
				</div>
			</div>
		</Page>
	);
};

export default PageUserSettingModel;
