/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as config from '../../common/config';
import Page from '../../components/page';
import Banners from '../../components/banners';

import './index.less';
import IMG_USER_01 from "../../common/images/user01.png";
import IMG_p5 from "../../common/images/p5.jpg";
import IMG_ICON_10 from "../../common/images/icon10.png";
import IMG_ICON_9 from "../../common/images/icon09.png";
import IMG_ICON_13 from "../../common/images/icon13.png";
import IMG_f1 from "../../common/images/f1.png";
import IMG_f3 from "../../common/images/f3.png";
import IMG_f6 from "../../common/images/f6.png";
import IMG_m1 from "../../common/images/m1.png";
import {useHistory} from "react-router-dom";
import * as CONST from "../../common/const";


const PageUserModel = props => {
	const history = useHistory();

	const [ userInfo, setUserInfo ] = useState(null);
	const getUserInfo = async () => {
		const userInfo = Utils.user.getInfo();
		setUserInfo(userInfo);
	};
	useEffect(() => {
		getUserInfo();
	}, [ 123 ]);

	return (
		<Page {...{ page: 'user', showFooter: true, title: '个人中心' }} {...props}>
			<div className="userBox ">
				<div className="userTop"><img src={IMG_USER_01}/></div>
				<div className="userOne">
					<div className="userOnePic"><img src={userInfo?.cover}/></div>
					<div className="userOneWz">{userInfo?.nickname}</div>
				</div>

			</div>

			{
				userInfo?.isvip == CONST.USER_VIP_IS && <div className="menuBox top285" onClick={() => history.push('/order')}>
					<div className="menuIcon"><img src={IMG_ICON_10}/></div>
					<div className="menuWz">我的服务预约</div>
					<div className="menuRt20"><img src={IMG_m1}/></div>
				</div>
			}

			<div className="menuBox top345" onClick={() => history.push('/user/setting')}>
				<div className="menuIcon"><img src={IMG_ICON_13} /></div>
				<div className="menuWz">修改资料</div>
				<div className="menuRt20"><img src={IMG_m1} /></div>
			</div>
			<div className="menuBox top405" onClick={() => history.push('/about')}>
				<div className="menuIcon"><img src={IMG_ICON_13} /></div>
				<div className="menuWz">关于我们</div>
				<div className="menuRt20"><img src={IMG_m1} /></div>
			</div>
			<div className="menuBox top465" onClick={() => history.push('/protocol')}>
				<div className="menuIcon"><img src={IMG_ICON_9} /></div>
				<div className="menuWz">协议政策</div>
				<div className="menuRt20"><img src={IMG_m1} /></div>
			</div>
			<div className="MHr"></div>
			{/*<!--误删，65px的高度-->*/}
		</Page>
	);
};

export default PageUserModel;
