/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {useHistory, useParams} from "react-router-dom";

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as config from '../../common/config';
import * as CONST from '../../common/const';
import Page from '../../components/page';
import Banners from '../../components/banners';

import './index.less';
import IMG_s8 from "../../common/images/s8.jpg";
import IMG_s9 from "../../common/images/s9.jpg";
import IMG_s10 from "../../common/images/s10.jpg";
import IMG_s11 from "../../common/images/s11.jpg";
import IMG_s12 from "../../common/images/s12.jpg";

const PageDetailModel = props => {
	const { id } = useParams();
	const history = useHistory();

	const [ userInfo, setUserInfo ] = useState(null);
	const getUserInfo = async () => {
		const userInfo = Utils.user.getInfo();
		setUserInfo(userInfo);
	};
	useEffect(() => {
		getUserInfo();
	}, [ 123 ]);

	const [ data, setData ] = useState(null);
	const getData = async () => {
		const result = await Request.get({
			url: '/product/get',
			data: { id }
		});

		if(!result) return;
		setData(result.data);
	};
	useEffect(() => {
		getData();
	}, [ 123 ]);
	if(!data) return null;

	return (
		<Page {...{ page: 'detail', title: '商品详情' }} {...props}>
			<div className="listBox ">
				<ul>
					<li>
						<div className="avatar"><img src={data.cover && config.imgPrefix + data.cover[0] || null} /></div>
						<div className="avatarTitle">{data.name}</div>
						{/*<div className="avatarMoney">价格：¥{data.price}</div>*/}
						{ data.intro && <div className="avatarText">{data.intro}</div> }
					</li>


				</ul>
			</div>

			{/*<!-- 商品详情介绍-->*/}
			<div className="listenBoxShow">
				<div className="listenTitle">
					<div className="listenTitleWz">商品详情介绍</div>
				</div>
				<div className="topicPhoto">
					<div dangerouslySetInnerHTML={{ __html: data.detail }} />
				</div>
			</div>
			{
				userInfo?.isvip == CONST.USER_VIP_IS && <>
					{/*<!--占位用误删-->*/}
					<div className="ft105" />
					<div className="tickeShow">
						<input name="" type="button" className="yuyuexiadan" value="服务预约" onClick={() => {
							history.push(`book/${id}`);
						}}/>
					</div>
				</>
			}
		</Page>
	);
};

export default PageDetailModel;
