/**
 * Created by jimmy on 2019/11/8.
 */
/**
 * Created by jimmy on 2019/8/13.
 */
import 'whatwg-fetch';
import * as Utils from './utils';
import * as CONST from './config';

export const getHeaders = (conf = {}) => {
	const token = window.sessionStorage.getItem('token');
	const openid = window.sessionStorage.getItem('openid');
	const _header = {
		"content-type":"application/json; charset=utf-8",
		"auth-token": token,
		"auth-openid": openid,
		"sys-token": 'a1s4md6cj7n8d9j0as2dq0weeq1okgmx',
		...conf
	};
	return _header;
};

export const getUploadHeaders = (conf = {}) => {
	const token = window.sessionStorage.getItem('token');
	const openid = window.sessionStorage.getItem('openid');
	const _header = {
		"auth-token": token,
		"auth-openid": openid,
		"sys-token": 'a1s4md6cj7n8d9j0as2dq0weeq1okgmx',
		...conf
	};
	return _header;
};

export const uploadFetch = async (settings = {}) => {
	const url = CONST.apiPrefix + settings.url;
	settings.body = settings.body || settings.data;
	delete settings.url;
	delete settings.data;
	settings.headers = getUploadHeaders(settings.headers);
	let response = await window.fetch(url, settings);
	// if(response.status && response.status === 401) {
	// 	Utils._alert('登录信息过期，请重新登录');
	// 	setTimeout(() => {
	// 		window.location.href = '/login';
	// 	}, 500);
	// 	return;
	// }
	response = await response.json();
	if(!settings.hideError && response.ok == -1) {
		Utils._alert(response.msg);
		return false;
	}
	return response;
};

export const post = async (settings) => {
	let url = CONST.apiPrefix + settings.url;
	Utils.loading.show();
	let response = await window.fetch(url, {
		method: 'POST',
		headers: getHeaders(settings.headers),
		body: JSON.stringify(settings.data || {})
	});
	Utils.loading.hide();
	if(response.status && response.status === 401) {
		Utils.user.wxLogin({}, () => window.location.reload());
		return;
	}

	response = await response.json();
	if(!settings.hideError && response.ok == -1) {
		Utils._alert(response.msg);
		return false;
	}
	return response;
};

export const get = async (settings = {}) => {
	let url = CONST.apiPrefix + settings.url;

	if(settings.data) {
		let queryArr = [];
		for(let i in settings.data) {
			if(i && settings.data[i]){
				queryArr.push(`${i}=${settings.data[i]}`);
			}
		}
		url += '?' + queryArr.join('&');
	}
	Utils.loading.show();
	let response = await window.fetch(url, {
		method: 'GET',
		headers: getHeaders(settings.headers),
	});
	Utils.loading.hide();
	if(response.status && response.status === 401) {
		Utils.user.wxLogin({}, () => window.location.reload());
		return;
	}

	response = await response.json();
	if(!settings.hideError && response.ok == -1) {
		Utils._alert(response.msg);
		return false;
	}
	return response;
};
