/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as config from '../../common/config';
import * as CONST from '../../common/const';
import Page from '../../components/page';
import Banners from '../../components/banners';

import './index.less';
import IMG_zm02 from "../../common/images/zm02.jpg";
import IMG_pdf from "../../common/images/pdf.png";

const PageOrderDetailModel = props => {
	const { id } = useParams();
	const history = useHistory();

	const [ data, setData ] = useState(null);
	const getData = async () => {
		const result = await Request.get({
			url: '/order/get',
			data: { id }
		});

		if(!result) return;
		setData(result.data);
	};
	useEffect(() => {
		getData();
	}, [ 123 ]);

	const [ appointTime, setAppointTime ] = useState('');
	const submitHandle = async (data, url) => {
		const result = await Request.post({
			url,
			data
		});
		if(!result) return;
		getData();
		setAppointTime('');
	};

	const [ previewImg, setPreviewImg ] = useState(false);
	const [ previewIndex, setPreviewIndex ] = useState(0);

	const _getFileName = nameStr => {
		if(!nameStr) return '';
		const nameArr = nameStr.split('/');
		if(nameArr?.length <= 0) return '';
		return nameArr[nameArr.length - 1] || '';
	}

	if(!data) return null;
	return (
		<Page {...{ page: 'orderDetail', title: '服务预约详情' }} {...props}>
			<div className="orderDetailTop">
				<span>服务预约编号：{data.serialNumber}</span>
				<span>服务预约时间：{data.createdAt && moment(data.createdAt).format('YYYY-MM-DD HH:mm')}</span>
			</div>

			<div className="orderDetailBlock">
				<img className="orderDetailCover" src={data.productInfo?.cover && config.imgPrefix + data.productInfo?.cover[0] || null} />
				{/*<div className="orderDetailInfo"><span className="wfk">价格：¥1233</span></div>*/}
				<div className="orderDetailInfo">服务预约状态：<span className="yfk">提交服务预约，等待初步诊疗方案</span></div>
				<div className="orderDetailInfo">备注：{data.processInfo?.extra}</div>
				<div className="orderDetailInfo">
					<span className="yfk">体检报告</span>
					<div className="attachment">
						{
							data.processInfo?.attachment && data.processInfo.attachment.map((item, idx) => (
								<div className="img" style={{ backgroundImage: `url(${config.imgPrefix + item})` }} key={idx} onClick={() => {
									// window.open(config.imgPrefix + item);
									setPreviewIndex(idx);
									setPreviewImg(true);
								}} />
							))
						}
					</div>
				</div>
			</div>

			{
				data.processList?.length > 0 && <>
					{
						data.processList.map((item, idx) => {
							if(item.status == CONST.ORDER_STATUS_TO_PAY1) {
								return (
									<div className="orderDetailBlock" key={idx}>
										<div className="orderDetailInfo flex">
											<span className="wfk">平台：诊疗服务</span>
											<span>{item.createdAt && moment(item.createdAt).format('YYYY-MM-DD HH:mm') || ''}</span>
										</div>
										<div className="orderDetailInfo">
											<div className="pdf" onClick={() => {
												if(item.attachment && item.attachment[0]) {
													window.open(config.imgPrefix + item.attachment[0]);
												}
											}}>
												<img src={IMG_pdf} />
												<span>点击查看PDF</span>
											</div>
											{ item.extra && <span>备注：{item.extra}</span> }
										</div>
									</div>
								)
							}
							if(item.status == CONST.ORDER_STATUS_PAID) {
								return (
									<div className="orderDetailBlock">
										<div className="orderDetailInfo flex">
											<span className="yfk">客户：线下付款成功</span>
											<span>{item.createdAt && moment(item.createdAt).format('YYYY-MM-DD HH:mm') || ''}</span>
										</div>
									</div>
								)
							}
							if(item.status == CONST.ORDER_STATUS_TO_APPOINT) {
								return (
									<>
										<div className="orderDetailBlock">
											<div className="orderDetailInfo flex">
												<span className="wfk">平台：细胞干预治疗方案</span>
												<span>{item.createdAt && moment(item.createdAt).format('YYYY-MM-DD HH:mm') || ''}</span>
											</div>
											<div className="orderDetailInfo">
												<div className="pdf" onClick={() => {
													if(item.attachment && item.attachment[0]) {
														window.open(config.imgPrefix + item.attachment[0]);
													}
												}}>
													<img src={IMG_pdf} />
													<span>点击查看PDF</span>
												</div>
												{ item.extra && <span>备注：{item.extra}</span> }
											</div>
										</div>
										{
											data.status == item.status && <div className="orderDetailBlock">
												<div className="orderDetailInfo flex">
													<span className="wfk">客户：预约时间选择</span>
												</div>
												<div className="orderDetailInfo">
													<div className="timeSelectBox">
														<span>点击选择预约时间</span>
														<input type="datetime-local" placeholder="请选择时间"  onChange={e => {
															let val = e.target.value;
															val = val.replace(/\//ig, '-').replace('T', ' ');
															setAppointTime(val);
														}} />
													</div>
													<div className="submitTimeBtn bt100" onClick={() => {
														if(!appointTime) {
															return Utils._alert('预约时间不能为空');
														}
														submitHandle({ id, appointTime }, '/order/appoint/submit');
													}}>提交</div>
													<span><span className="wfk">温馨提示：</span>亲爱的客户您好，你所选的预约时间可能会和医生时间冲突请等待客服电话和您确定最终时间</span>
												</div>
											</div>
										}
									</>
								)
							}
							if(item.status == CONST.ORDER_STATUS_APPOINT_TO_CONFIRM) {
								return (
									<div className="orderDetailBlock">
										<div className="orderDetailInfo flex">
											<span className="yfk">客户：预约时间选择</span>
											<span>{item.createdAt && moment(item.createdAt).format('YYYY-MM-DD HH:mm') || ''}</span>
										</div>
										<div className="orderDetailInfo flex">
											<span>预约时间：<span className="yfk">{item.appointTime}</span></span>
										</div>
									</div>
								)
							}
							if(item.status == CONST.ORDER_STATUS_APPOINT_CONFIRMED) {
								return (
									<div className="orderDetailBlock">
										<div className="orderDetailInfo flex">
											<span className="wfk">平台：确认时间及地点</span>
											<span>{item.createdAt && moment(item.createdAt).format('YYYY-MM-DD HH:mm') || ''}</span>
										</div>
										<div className="orderDetailInfo flex">
											<span>用户姓名：<span className="yfk">{data.processInfo?.name}</span></span>
											<span>联系电话：<span className="yfk">{data.processInfo?.mobile}</span></span>
										</div>
										<div className="orderDetailInfo flex">
											<span>时间：<span className="yfk">{item.appointTime}</span></span>
										</div>
										<div className="orderDetailInfo flex">
											<span>地点：<span className="yfk">{item.appointAddress}</span></span>
										</div>
									</div>
								)
							}
							if(item.status == CONST.ORDER_STATUS_COMPLETED) {
								return (
									<div className="orderDetailBlock">
										<div className="orderDetailInfo flex">
											<span className="wfk">平台：服务预约已完成</span>
											<span>{item.createdAt && moment(item.createdAt).format('YYYY-MM-DD HH:mm') || ''}</span>
										</div>
										<div className="orderDetailInfo flex">
											<span><span className="wfk">温罄提示：</span>亲爱的客户您好，1-3 个月我们会预约您体检复诊，请关注我们的公众号和客服电话 <a href="tel:13601603810">13601603810</a></span>
										</div>
									</div>
								)
							}
							if(data.status == item.status && item.status == CONST.ORDER_STATUS_VISIT_TO_APPOINT) {
								return (
									<div className="orderDetailBlock">
										<div className="orderDetailInfo flex">
											<span className="wfk">客户：预约体检复诊时间</span>
										</div>
										<div className="orderDetailInfo">
											<div className="timeSelectBox">
												<span>点击选择预约时间</span>
												<input type="datetime-local" placeholder="请选择时间"  onChange={e => {
													let val = e.target.value;
													val = val.replace(/\//ig, '-').replace('T', ' ');
													setAppointTime(val);
												}} />
											</div>
											<div className="submitTimeBtn bt100" onClick={() => {
												if(!appointTime) {
													return Utils._alert('预约时间不能为空');
												}
												submitHandle({ id, appointTime }, '/order/visit/appoint/submit');
											}}>提交</div>
											<span><span className="wfk">温馨提示：</span>亲爱的客户您好，你所选的预约时间可能会和医生时间冲突请等待客服电话和您确定最终时间</span>
										</div>
									</div>
								)
							}
							if(item.status == CONST.ORDER_STATUS_VISIT_APPOINT_TO_CONFIRM) {
								return (
									<div className="orderDetailBlock">
										<div className="orderDetailInfo flex">
											<span className="yfk">客户：预约体检复诊时间</span>
											<span>{item.createdAt && moment(item.createdAt).format('YYYY-MM-DD HH:mm') || ''}</span>
										</div>
										<div className="orderDetailInfo flex">
											<span>预约时间：<span className="yfk">{item.appointTime}</span></span>
										</div>
									</div>
								)
							}
							if(item.status == CONST.ORDER_STATUS_VISIT_APPOINT_CONFIRMED) {
								return (
									<div className="orderDetailBlock">
										<div className="orderDetailInfo flex">
											<span className="wfk">平台：确认复诊时间及地点</span>
											<span>{item.createdAt && moment(item.createdAt).format('YYYY-MM-DD HH:mm') || ''}</span>
										</div>
										<div className="orderDetailInfo flex">
											<span>用户姓名：<span className="yfk">{data.processInfo?.name}</span></span>
											<span>联系电话：<span className="yfk">{data.processInfo?.mobile}</span></span>
										</div>
										<div className="orderDetailInfo flex">
											<span>时间：<span className="yfk">{item.appointTime}</span></span>
											<span>医生姓名：<span className="yfk">{item.doctorName}</span></span>
										</div>
										<div className="orderDetailInfo flex">
											<span>医生电话：<span className="yfk">{item.doctorMobile}</span></span>
										</div>
										<div className="orderDetailInfo flex">
											<span>医院地址：<span className="yfk">{item.appointAddress}</span></span>
										</div>
									</div>
								)
							}
							if(item.status == CONST.ORDER_STATUS_VISIT_COMPLETED) {
								return (
									<div className="orderDetailBlock">
										<div className="orderDetailInfo flex">
											<span className="wfk">平台：完成复诊，服务预约结束</span>
											<span>{item.createdAt && moment(item.createdAt).format('YYYY-MM-DD HH:mm') || ''}</span>
										</div>
										<div className="orderDetailInfo flex">
											<span>亲爱的客户您好，感谢您选择和携！说明文字文字感谢您选择和携！说明文字文字感谢您选择和携！说明文字文字</span>
										</div>
									</div>
								)
							}
							return null
						})
					}
				</>
			}

			{
				<PhotoProvider {...{
					style: { zIndex: 99 },
					visible: previewImg && data.processInfo?.attachment.length > 0,
					images: data.processInfo.attachment.map((item) => ({ src: config.imgPrefix + item, key: item })),
					photoClosable: false,
					onClose: () => setPreviewImg(false),
					index: previewIndex,
					onIndexChange: setPreviewIndex
				}} />
			}
		</Page>
	);
};

export default PageOrderDetailModel;
