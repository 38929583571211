/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import './index.less';

import * as Utils from '../../common/utils';

import IMG_FOOTER_HOME from '../../common/images/home.png';
import IMG_FOOTER_HOME_SELECTED from '../../common/images/home.png';
import IMG_FOOTER_STORE from '../../common/images/home.png';
import IMG_FOOTER_STORE_SELECTED from '../../common/images/home.png';
import Button from "../button";
import * as Request from "../../common/request";
import * as Const from "../../common/config";

import IMG_F1 from '../../common/images/f1.png';
import IMG_F2 from '../../common/images/f2.png';
import IMG_F3 from '../../common/images/f3.png';
import IMG_F4 from '../../common/images/f4.png';
import IMG_F5 from '../../common/images/f5.png';
import IMG_F6 from '../../common/images/f6.png';
import IMG_FANHUI from "../../common/images/fanhui.png";
import IMG_HOME from "../../common/images/home.png";

const CompModel = props => {
	const history = useHistory();
	const location = useLocation();
	const { page, showFooter = false, showHeader = true, title = '', style = {} } = props;

	useEffect(() => {
		Utils.setTitle(title);
	}, [ title ]);

	useEffect(() => {
		const userInfo = Utils.user.getInfo();
		if(userInfo && !userInfo.mobile && location.pathname.indexOf('login') == -1) {
			history.push('/login');
		}
	}, [ 123 ]);


	const isInWechat = Utils.getUA().isInWechat;

	return (
		<div className="page-container" style={style}>
			{
				!!showHeader && title && <div className="TopBox ">{title}
					{
						!['home', 'service', 'user'].includes(page) && <>
							<div className="TopReturn"><a href="javascript:void(0);" onClick={() => history.goBack()}><img src={IMG_FANHUI}/></a></div>
							<div className="TopHome"><a href="/"><img src={IMG_HOME}/></a></div>
						</>
					}
				</div>
			}
			{ props.children }
			{
				!!showFooter && <div className="footer">
					<div className="footBox" onClick={() => history.push('home')}>
						<div className="footIcon"><img src={page == 'home' ? IMG_F2 : IMG_F1} /></div>
						<div className="footWz footOn">首页</div>
					</div>
					<div className="footBox" onClick={() => history.push('service')}>
						<div className="footIcon"><img src={page == 'service' ? IMG_F4 : IMG_F3}/></div>
						<div className="footWz">服务</div>
					</div>
					<div className="footBox" onClick={() => history.push('user')}>
						<div className="footIcon"><img src={page == 'user' ? IMG_F6 : IMG_F5}/></div>
						<div className="footWz">个人中心</div>
					</div>
				</div>
			}
		</div>
	);
};

export default CompModel;
