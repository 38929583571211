/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {useHistory, useParams} from "react-router-dom";

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as config from '../../common/config';
import Page from '../../components/page';
import Banners from '../../components/banners';

import './index.less';
import IMG_s8 from "../../common/images/s8.jpg";
import IMG_s9 from "../../common/images/s9.jpg";
import IMG_s10 from "../../common/images/s10.jpg";
import IMG_s11 from "../../common/images/s11.jpg";
import IMG_s12 from "../../common/images/s12.jpg";
import moment from "moment";

const PageArticleDetailModel = props => {
	const { id } = useParams();
	const history = useHistory();

	const [ data, setData ] = useState(null);
	const getData = async () => {
		const result = await Request.get({
			url: '/article/get',
			data: { id }
		});

		if(!result) return;
		setData(result.data);
	};
	useEffect(() => {
		getData();
	}, [ 123 ]);
	if(!data) return null;

	return (
		<Page {...{ page: 'articleDetail', title: data?.title }} {...props}>
			<div className="qaBox">
				<h2>{data?.title}</h2>
				<div className="articleTime">发布时间：{data?.createdAt && moment(data.createdAt).format('YYYY-MM-DD HH:mm')}</div>

				<div dangerouslySetInnerHTML={{ __html: data.detail }} />
			</div>
			<div className="ft50"></div>
			{/*<!--占位用误删-->*/}
		</Page>
	);
};

export default PageArticleDetailModel;
