/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as Const from '../../common/config';
import Page from '../../components/page';
import Banners from '../../components/banners';

import './index.less';
import IMG_F5 from "../../common/images/f5.png";
import IMG_b1 from "../../common/images/b1.jpg";
import IMG_b2 from "../../common/images/b2.jpg";
import IMG_b3 from "../../common/images/b3.jpg";
import IMG_ICON_01 from "../../common/images/icon01.png";
import IMG_ICON_02 from "../../common/images/icon02.png";
import IMG_ICON_03 from "../../common/images/icon03.png";
import IMG_ICON_04 from "../../common/images/icon04.png";
import IMG_ICON_05 from "../../common/images/icon05.png";
import IMG_s7 from "../../common/images/s7.jpg";
import IMG_s8 from "../../common/images/s8.jpg";
import IMG_s9 from "../../common/images/s9.jpg";
import IMG_s10 from "../../common/images/s10.jpg";
import IMG_s11 from "../../common/images/s11.jpg";
import IMG_s12 from "../../common/images/s12.jpg";
import IMG_zm02 from "../../common/images/zm02.jpg";
import IMG_zm03 from "../../common/images/zm03.jpg";
import IMG_zm04 from "../../common/images/zm04.jpg";
import IMG_FANHUI from "../../common/images/fanhui.png";
import IMG_HOME from "../../common/images/home.png";
import IMG_SUCCESS from "../../common/images/chenggong.png";
import {useHistory, useParams} from "react-router-dom";


const PageResultModel = props => {
	const { type } = useParams();
	const history = useHistory();

	return (
		<Page {...{ page: 'result', showHeader: true, title: '服务预约成功' }} {...props}>
			{/*<!-- TOP BEING-->*/}
			<div className="successBox">
				<span><img src={IMG_SUCCESS} width="120px"/></span>
				<h1>服务预约成功，请等待客服电话回馈</h1>
				<h2>
					<button className="bt40" type="submit" id="submitButton" onClick={() => history.push('/user')}>进入个人中心</button>
				</h2>
				<h3></h3>
			</div>
		</Page>
	);
};

export default PageResultModel;
