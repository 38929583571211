/**
 * Created by jimmy on 2022/9/25.
 */

import React, { useState, useEffect } from 'react';
import {useHistory, useParams} from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Pagination, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import * as CONST from '../../common/const';
import './index.less';

const CompCarouselModel = props => {
  const history = useHistory();
  const { style = {}, list = [], mode = '', slidesPerView = 3 } = props;

  if(!list || list.length <= 0) return null;

  // const mode = ''; // 默认
  // const mode = 'multi-in-line'; // 一行多个
  let _config = {
    onSlideChange: () => {
      console.log('slide change');
    },
    onSwiper: (swiper) => {
      console.log(swiper);
    }
  };
  if(mode == 'multi-in-line') {
    _config = {
      ..._config,
      modules: [ FreeMode ],
      freeMode: true,
      spaceBetween: 10,
      slidesPerView
    }
  }else{
    _config = {
      ..._config,
      modules: [ Pagination ],
      pagination: {
        bulletActiveClass: 'comp-bullet-active'
      },
    }
  }

  return (
    <section className="comp-carousel-wrapper" style={style}>
      <Swiper {..._config}>
        {
          list.map((item = {}, idx) => {
            return (
              <SwiperSlide key={idx}>
                <div className="comp-carousel-item">{props.renderItem(item, idx)}</div>
                {/*<div className="comp-carousel-item" style={{ backgroundImage: `url(${CONST.MEDIA_HOST + item.cover})` }} onClick={() => {
                  if(item.type == CONST.BANNER_TARGET_TYPE_WEBSITE && item.target.startsWith('http')) {
                    window.location.href = item.target;
                  }else if(item.type == CONST.BANNER_TARGET_TYPE_WX_PRODUCT_DETAIL){
                    history.push(`/product/${item.target}`);
                  }else if(item.type == CONST.BANNER_TARGET_TYPE_WX_BRAND){
                    history.push(`/brand/${item.target}`);
                  }else if(item.type == CONST.BANNER_TARGET_TYPE_MINI_PAGE){
                    history.push(`/page/${item.target}`);
                  }
                }} />*/}
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  );
};

export default CompCarouselModel;
