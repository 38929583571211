export const BANNER_TARGET_TYPE_DEFAULT = 1;
export const BANNER_TARGET_TYPE_LINK = 2;
export const BANNER_TARGET_TYPE_PRODUCT = 3;
export const BANNER_TARGET_TYPE_ARTICLE = 4;

export const BANNER_STATUS_ONLINE = 1;
export const BANNER_STATUS_OFFLINE = 2;

export const ADMIN_TYPE_SUPER = 1;
export const ADMIN_TYPE_GENERAL = 2;

export const PRODUCT_STATUS_ONLINE = 1;
export const PRODUCT_STATUS_OFFLINE = 2;
export const PRODUCT_STATUS_SALE_OUT = 3;

export const ORDER_STATUS_TO_PAY = 1;
export const ORDER_STATUS_TO_PAY1 = 11;
export const ORDER_STATUS_PAID = 2;
export const ORDER_STATUS_TO_APPOINT = 3;
export const ORDER_STATUS_APPOINT_TO_CONFIRM = 4;
export const ORDER_STATUS_APPOINT_CONFIRMED = 5;
export const ORDER_STATUS_COMPLETED = 6;
export const ORDER_STATUS_VISIT_TO_APPOINT = 7;
export const ORDER_STATUS_VISIT_APPOINT_TO_CONFIRM = 8;
export const ORDER_STATUS_VISIT_APPOINT_CONFIRMED = 9;
export const ORDER_STATUS_VISIT_COMPLETED = 10;

export const USER_SEX_MAN = 1;
export const USER_SEX_WOMEN = 2;
export const USER_SEX_UNKNOWN = 3;

export const USER_VIP_IS = 1;
export const USER_VIP_NOT = 2;
