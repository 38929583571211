import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from "react-router-dom";
import 'react-photo-view/dist/react-photo-view.css';

import * as Utils from './common/utils';
import './index.less';
import * as serviceWorker from './serviceWorker';

import PageHome from './pages/home';
import PageService from './pages/service';
import PageServiceDetail from './pages/serviceDetail';
import PageUser from './pages/user';
import PageBook from './pages/book';
import PageResult from './pages/result';
import PageOrder from './pages/order';
import PageOrderDetail from "./pages/orderDetail";
import PageUserSetting from "./pages/userSetting";
import PageAbout from "./pages/about";
import PageLogin from "./pages/login";
import PageProtocol from "./pages/protocol";
import PageProtocolDetail from "./pages/protocol/detail";
import PageArticleDetail from "./pages/articleDetail";

const App = () => (
	<Router>
		<div>
			<Route exact path="/" component={PageHome} />
			<Route exact path="/home" component={PageHome} />
			<Route exact path="/login" component={PageLogin} />
			<Route exact path="/service" component={PageService} />
			<Route exact path="/service/:id" component={PageServiceDetail} />
			<Route exact path="/article/:id" component={PageArticleDetail} />
			<Route exact path="/service/book/:id" component={PageBook} />
			<Route exact path="/user" component={PageUser} />
			<Route exact path="/user/setting" component={PageUserSetting} />
			<Route exact path="/order" component={PageOrder} />
			<Route exact path="/about" component={PageAbout} />
			<Route exact path="/order/:id" component={PageOrderDetail} />
			<Route exact path="/result/:type" component={PageResult} />
			<Route exact path="/protocol" component={PageProtocol} />
			<Route exact path="/protocol/:type" component={PageProtocolDetail} />
		</div>
	</Router>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

Utils.user.wxLogin({}, () => window.location.reload());
// Utils.user.wxsign();
