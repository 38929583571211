/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ImageCompressor from 'js-image-compressor';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as config from '../../common/config';
import Page from '../../components/page';
import Banners from '../../components/banners';

import './index.less';
import IMG_CAMERA from "../../common/images/camera.png";
import {useHistory, useParams} from "react-router-dom";
import {PhotoProvider} from "react-photo-view";


const PageBookModel = props => {
	const { id } = useParams();
	const history = useHistory();

	const [ name, setName ] = useState('');
	const [ mobile, setMobile ] = useState('');
	const [ attachment, setAttachment ] = useState([]);
	const [ extra, setExtra ] = useState('');
	const submit = async () => {
		if(!name) return Utils._alert('请输入名字');
		if(!mobile) return Utils._alert('请输入手机号码或者电话');
		if(!attachment || attachment.length <= 0) return Utils._alert('请上传体检报告');


		const result = await Request.post({
			url: '/order/submit',
			data: {
				productId: id,
				name,
				mobile,
				attachment,
				extra,
			}
		});
		if(!result) return;
		history.push('/result/success');
	};
	const uploadHandle = async (e, idx = 0) => {
		const filesInfo = e.target.files;
		const len = filesInfo.length;
		const attachmentLength = attachment.length;
		const _attachment = [ ...attachment ];

		for(let i = 0; i < len; i++) {
			const selectedFile = filesInfo[i];
			const _compressHelp = ($file) => {
				return new Promise((resolve) => {
					new ImageCompressor({
						file: $file,
						quality: 0.8,
						maxWidth: 2000,
						maxHeight: 2000,
						// width: 2000,
						// height: 2000,
						// minWidth: 200,
						// minHeight: 200,
						convertSize: Infinity,
						loose: true,
						redressOrientation: true,
						// 压缩前回调
						beforeCompress: function (result) {
							console.log('压缩之前图片尺寸大小: ', result.size);
							console.log('mime 类型: ', result.type);
						},
						// 压缩成功回调
						success: async function (cresult) {
							console.log('result: ', cresult)
							console.log('压缩之后图片尺寸大小: ', cresult.size);
							console.log('mime 类型: ', cresult.type);
							console.log('实际压缩率： ', ((selectedFile.size - cresult.size) / selectedFile.size * 100).toFixed(2) + '%');
							cresult = new File([cresult], selectedFile.name);
							resolve(cresult);
						}
					});
				})
			}

			const selectedFileCompress = await _compressHelp(selectedFile);
			const formData = new FormData();
			formData.append('file', selectedFileCompress);

			const result = await Request.uploadFetch({
				url: '/order/attachment/upload',
				method:'POST',
				data: formData
			});
			if(!result) return;

			_attachment[attachmentLength + i] = result.data.src;
		}
		setAttachment(_attachment);
	};

	const [ previewImg, setPreviewImg ] = useState(false);
	const [ previewIndex, setPreviewIndex ] = useState(0);

	return (
		<Page {...{ page: 'book', title: '服务预约' }} {...props}>
			<div className="yuyueBox mg30">
				<div className="datailsTitle">
					<div className="datailsTitleWz">填写预约信息</div>
				</div>
				<div className="editText">
					<input id="textfield" type="text" className="input01" placeholder="请输入姓名" onChange={e => setName(e.target.value)} />
				</div>
				<div className="editText">
					<input id="textfield" type="text" className="input01" maxLength={11} placeholder="请输入电话或手机号码" onChange={e => setMobile(e.target.value)} />
				</div>
				<div className="coEditText coEditTextColumn">
					<label>上传体检报告照片（{attachment?.length || 0}/30）</label>
					<div className="orderPicItemBox">
						{
							attachment?.length > 0 && attachment.map((item, idx) => {
								return (
									<div className="orderPicItem" key={idx} onClick={() => {
										setPreviewIndex(idx);
										setPreviewImg(true);
									}}><img src={config.imgPrefix + item} /></div>
								)
							})
						}
						{/*<div className="orderPicItem"><img src={IMG_s7} /></div>
						<div className="orderPicItem"><img src={IMG_s7} /></div>
						<div className="orderPicItem"><img src={IMG_s7} /></div>
						<div className="orderPicItem"><img src={IMG_s7} /></div>
						<div className="orderPicItem"><img src={IMG_s7} /></div>
						<div className="orderPicItem"><img src={IMG_s7} /></div>
						<div className="orderPicItem"><img src={IMG_s7} /></div>
						<div className="orderPicItem"><img src={IMG_s7} /></div>*/}
						{
							attachment && attachment.length < 30 && <div className="orderPicItem">
								<input className="uploadItem" type="file" multiple="multiple" accept= "image/*" onChange={(e) => {
									uploadHandle(e);
								}} />
								<img className="uploadCamera" src={IMG_CAMERA} />
							</div> || null
						}
					</div>
				</div>
				<div className="addBox">
					<textarea name="" cols="" rows="" className="ipt122" placeholder="请填写简单需求" onChange={e => setExtra(e.target.value)}></textarea>
				</div>
			</div>
			<div className="ft50"></div>
			{/*<!--占位用误删-->*/}
			<div className="tickeShow">
				<input name="" type="button" className="yuyuexiadan" value="提交服务预约" onClick={submit} />
			</div>

			{
				<PhotoProvider {...{
					style: { zIndex: 99 },
					visible: previewImg && attachment.length > 0,
					images: attachment.map((item) => ({ src: config.imgPrefix + item, key: item })),
					photoClosable: false,
					onClose: () => setPreviewImg(false),
					index: previewIndex,
					onIndexChange: setPreviewIndex
				}} />
			}
		</Page>
	);
};

export default PageBookModel;
