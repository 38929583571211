/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as Const from '../../common/config';
import Page from '../../components/page';
import Banners from '../../components/banners';

import './index.less';
import IMG_F5 from "../../common/images/f5.png";
import IMG_b1 from "../../common/images/b1.jpg";
import IMG_b2 from "../../common/images/b2.jpg";
import IMG_b3 from "../../common/images/b3.jpg";
import IMG_ICON_01 from "../../common/images/icon01.png";
import IMG_ICON_02 from "../../common/images/icon02.png";
import IMG_ICON_03 from "../../common/images/icon03.png";
import IMG_ICON_04 from "../../common/images/icon04.png";
import IMG_ICON_05 from "../../common/images/icon05.png";
import IMG_s7 from "../../common/images/s7.jpg";
import IMG_zm02 from "../../common/images/zm02.jpg";
import IMG_zm03 from "../../common/images/zm03.jpg";
import IMG_zm04 from "../../common/images/zm04.jpg";
import IMG_FANHUI from "../../common/images/fanhui.png";
import IMG_HOME from "../../common/images/home.png";


const PageServiceModel = props => {

	const [ productList, setProductList ] = useState([]);
	const getProductList = async () => {
		const result = await Request.get({
			url: '/product/list'
		});

		if(!result) return;
		setProductList(result.data.list);
	};
	useEffect(() => {
		getProductList();
	}, [ 123 ]);

	return (
		<Page {...{ page: 'service', showFooter: true, title: '服务' }} {...props}>
			{/*<!-- ICON BEING-->*/}
			{/*<div className="iconBox">
				<div className="iconShow"><a href="group.html"> <span><img src={IMG_ICON_01} /></span>
					<h1>拼团活动</h1>
				</a></div>
				<div className="iconShow"><a href="ahc.html"> <span><img src={IMG_ICON_02} /></span>
					<h1>AHC</h1>
				</a></div>
				<div className="iconShow"><a href="integral.html"> <span><img src={IMG_ICON_03} /></span>
					<h1>积分收益</h1>
				</a></div>
				<div className="iconShow"><a href="extension.html"> <span><img src={IMG_ICON_04} /></span>
					<h1>推广中心</h1>
					<!-- 有操作动作 显示气泡-->
				</a></div>
				<div className="iconShow"><a href="user.html"> <span><img src={IMG_ICON_05} /></span>
					<h1>个人中心</h1>
					<!-- 有操作动作 显示气泡-->
				</a></div>
			</div>*/}
			{/*<!-- 产品列表-->*/}
			<div className="listenVedio">
				{/*<!-- title BEING-->*/}
				<div className="titleMiss">
					<h2>细胞专区</h2>
					<h1>He jie Cell zone</h1>
					<h3></h3>
				</div>
				<div className="vedioCenter">
					{/*<!--循环-->*/}
					{
						productList.map((item, idx) => {
							return (
								<div className="freeList" key={idx}>
									<a href={`/service/${item.id}`}>
										<span><img src={item.cover && item.cover[0] || null} /></span>
										<h1>{item.name}</h1>
										{/*<h3>价格：¥{item.price}</h3>*/}
									</a>
								</div>
							)
						})
					}
				</div>
			</div>
			<div className="ft50"></div>
			{/*	<!--占位用误删-->*/}
		</Page>
	);
};

export default PageServiceModel;
